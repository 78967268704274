import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import './all.sass'
import '../scss/index.scss'
import useSiteMetadata from './SiteMetadata'
import ThemeContext from '../context/ThemeContext'
import Navbar from '../components/Navbar'
import Map from '../components/Map'

const TemplateWrapper = ({ children, activeNode, helmet }) => {
  const { title, description } = useSiteMetadata()

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div>
          <Helmet>
            <html lang="en" />
            <title>{title}</title>
            <meta name="description" content={description} />

            <link
              rel="booklovers-icon"
              sizes="180x180"
              href="/img/booklovers-logo.png"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/booklovers-32x32.png"
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/booklovers-16x16.png"
              sizes="16x16"
            />

            <link
              rel="mask-icon"
              href="/img/safari-pinned-tab.svg"
              color="#ff4400"
            />
            <meta name="theme-color" content="#fff" />

            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content="/" />
            <meta property="og:image" content="/img/book-logo.png" />
          </Helmet>
          {helmet || ''}
          <Navbar prevPath={theme.prevPath} showNav={theme.showNav} hideNav={theme.hideNav} navIsVisible={theme.navIsVisible} updatePrevPath={theme.updatePrevPath} />
          { activeNode ? <Map activeNode={activeNode} /> : null }
          <div className={`inner ${activeNode ? 'map' : ''}`}>{children}</div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default TemplateWrapper
// export const query = graphql`
//   fragment GenericContextFragment on MarkdownRemark {
//     id
//     frontmatter {
//       title
//     }
//     fields {
//       slug
//       latitude
//       longitude
//       locations {
//         id
//         frontmatter {
//           title
//           templateKey
//         }
//         fields {
//           slug
//           latitude
//           longitude
//           categories {
//             name
//             image {
//               childImageSharp {
//                 fluid(maxWidth: 120, quality: 72) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
  
// `