import React from 'react'
import {Component} from 'react';
import ReactMapGL, {Marker, NavigationControl} from 'react-map-gl';
import { StaticQuery, graphql } from "gatsby"
import PreviewCompatibleImage from './PreviewCompatibleImage'
import MapStyle from './MapStyle'
import AniLink from "gatsby-plugin-transition-link/AniLink";

class Map extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this)
    this.state = {
      viewport: {
        width: typeof window !== 'undefined' && window && window.innerWidth ? window.innerWidth : 400,
        height: typeof window !== 'undefined' && window && window.innerHeight ? window.innerHeight : 400,
        latitude: 40.7128,
        longitude: -74.0060,
        zoom: 12
      }
    }
    const node = this.state.activeNode ? this.state.activeNode : this.props.activeNode
    this.activeNode = node
    let nodeLocations = []
    if (node && node.fields && node.fields.locations) {
      nodeLocations = node.fields.locations
    } else if (node && node.fields && node.fields.neighborhoods) {
      nodeLocations = node.fields.neighborhoods
    } else if (node && node.fields && node.fields.neighborhood && node.fields.neighborhood.fields && node.fields.neighborhood.fields.locations) {
      nodeLocations = node.fields.neighborhood.fields.locations
    }
    nodeLocations = nodeLocations || []
    this.nodeLocations = nodeLocations.filter(loc => loc && loc.fields && loc.fields.latitude && loc.fields.longitude)
    switch(node && node.frontmatter && node.frontmatter.templateKey ? node.frontmatter.templateKey : '') {

      case 'itinerary-template':
        this.state.viewport.zoom = 13
        break
      case 'location-template':
      case 'neighborhood-template':
        this.state.viewport.zoom = 13
        break
      default:
        this.state.viewport.zoom = 12
    }
    this.state.viewport.latitude = node && node.fields && node.fields.latitude ? node.fields.latitude : this.state.viewport.latitude
    this.state.viewport.longitude = node && node.fields && node.fields.longitude ? node.fields.longitude : this.state.viewport.longitude
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      this.updateDimensions()
      window.addEventListener('resize', this.updateDimensions);
    }
    setTimeout(this.updateDimensions, 500)
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateDimensions);
    }
  }
  updateDimensions() {
    if (typeof window !== 'undefined') {
      var cSide = document.querySelector('.cSide-inner')
      const viewport = this.state.viewport
      viewport.width = window.innerWidth - (cSide && cSide.clientWidth && (cSide.clientWidth / window.innerWidth) < 0.85 ? cSide.clientWidth : 0)
      viewport.height = window.innerHeight
      this.setState({
        viewport
      });
    }
  }

  componentDidMount() {
    if (!this.activeNode || !this.activeNode.frontmatter || !this.activeNode.frontmatter.templateKey || this.activeNode.frontmatter.templateKey !== 'itinerary-template') {
      return
    }
    const map = this.map.getMap()
    map.on('load', () => {
      const geoJson = {
        id: 'route',
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: this.nodeLocations.map((l => [l.fields.longitude, l.fields.latitude]))
            }
          }
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#D53A1D',
          'line-width': 7
        }
      }
      map.addLayer(geoJson)
    })
  }


  render() {
    let nodeLocations = this.nodeLocations
    const isMobile = typeof window !== 'undefined' && window && window.innerWidth && window.innerWidth < 768 ? window.innerWidth < 768 : false
    return (
      <ReactMapGL mapStyle={MapStyle} ref={(e) => { this.map = e; }}
        {...this.state.viewport}
        onViewportChange={(viewport) => this.setState({viewport})}
        scrollZoom={false}
        dragPan={!isMobile}
        dragRotate={!isMobile}
      >
      <div style={{position: 'absolute', right: 15, bottom: 25}}>
          <NavigationControl />
        </div>
        {nodeLocations.map( (loc) => {
          const icon = loc && loc.fields && loc.fields.categories && loc.fields.categories.length && loc.fields.categories[0] && loc.fields.categories[0].image ? loc.fields.categories[0].image : (loc && loc.frontmatter && loc.frontmatter.icon ? loc.frontmatter.icon : '/img/stack-of-books.png' )
          const isLocationTemplate = this.activeNode && this.activeNode.frontmatter && this.activeNode.frontmatter.templateKey === 'location-template'
          const isCurrentLocation = isLocationTemplate && loc && loc.id && loc.id === this.activeNode.id
          return loc && loc.fields && loc.fields.latitude && loc.fields.longitude ? (
            <Marker
              longitude={loc.fields.longitude}
              latitude={loc.fields.latitude}
              offsetTop={-20}
              offsetLeft={-10}
              key={loc.fields.slug}
            >
              <AniLink className={`cPin ${this.activeNode && this.activeNode.frontmatter && this.activeNode.frontmatter.templateKey === undefined ? '' : 'cPin--smaller'} ${isLocationTemplate ? (isCurrentLocation ? 'current' : 'non-current') : ''}`} data-category={loc && loc.fields && loc.fields.categories && loc.fields.categories.length && loc.fields.categories[0] ? loc.fields.categories[0].name : '' } fade to={loc.fields.slug} duration={1.3}>
                <div className="cPin-inner" style={{backgroundImage: icon && icon.url ? `url(${icon.url})` : 'icon'}}>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: icon,
                      alt: `thumbnail image for ${loc.frontmatter.title}`,
                      classes: 'cPin-image'
                    }}
                  />
                </div>
              </AniLink>
            </Marker>
          ) : null
        })}
      </ReactMapGL>
      );
    }
  }

  export default ({activeNode}) => (
    null
    // <StaticQuery
    // query={graphql`
    // query MapCityQuery {
    //   allMarkdownRemark(
    //     sort: { order: DESC, fields: [frontmatter___date] }
    //     filter: { frontmatter: { templateKey: { eq: "city-template" } } }
    //     ) {
    //       edges {
    //         node {
    //           id
    //           fields {
    //             slug
    //             neighborhoods {
    //               id
    //               fields {
    //                 slug
    //                 locations {
    //                   id
    //                   fields {
    //                     slug
    //                     categories {
    //                       name
    //                       image {
    //                         childImageSharp {
    //                           fluid(maxWidth: 120, quality: 50) {
    //                             src
    //                           }
    //                         }
    //                       }
    //                     }
    //                   }
    //                   frontmatter {
    //                     title
    //                     latitude
    //                     longitude
    //                     address {
    //                       address
    //                       latitude
    //                       longitude
    //                     },
    //                     icon {
    //                       childImageSharp {
    //                         fluid(maxWidth: 120, quality: 100) {
    //                           src
    //                         }
    //                       }
    //                     }
    //                   }
    //                 }
    //               }
    //               frontmatter {
    //                 title
    //                 icon {
    //                   childImageSharp {
    //                     fluid(maxWidth: 120, quality: 100) {
    //                       src
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //           frontmatter {
    //             title
    //             templateKey
    //           }
    //         }
    //       }
    //     }
    //   }
    //   `}
    //   render={(data, count) => <div className="cMap"><Map data={data} activeNode={activeNode} count={count} /></div>}
    //   />
      )
