import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from "../context/ThemeContext"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHomepage: false,
      isMap: false,
      titleCentered: false,
      titleHidden: false,
      currentPage: typeof window !== 'undefined' ? window.location.pathname : ''
    }
  }

  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    const pathname = window.location.pathname;
    if (pathname === '/') {
      this.setState({ isHomepage: true, titleCentered: true })
      window.addEventListener("scroll", this.handleScroll);
    }
    else if (pathname.indexOf("story/") > 0) {
      this.setState({ titleHidden: true })
    }
    else if (pathname.indexOf("city/") > 0 || pathname.indexOf("neighborhood/") || pathname.indexOf("location/")) {
      this.setState({ isMap: true, titleCentered: true })
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (typeof window === 'undefined') {
      return
    }
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (typeof window === 'undefined') {
      return
    }
    const currentScrollPos = window.pageYOffset;
    const titleCentered = currentScrollPos < 100;
    this.setState({
      titleCentered
    });
  };

  render() {
    return (
      <ThemeContext.Consumer>
        {theme => (
          <nav
            className={`cNavbar navbar ${!theme.navIsVisible ? 'hidden' : ''}`}
            role="navigation"
            aria-label="main-navigation"
          >
            <AniLink
              className={`cNavbar-main ${!this.state.titleCentered ? "scrolled" : ''} ${this.state.isMap ? "isMap" : ''}`}
              fade to="/menu"
              duration={0.25}
              onClick={() => theme.updatePrevPath(window.location.pathname)}
              state={{currentPage: this.state.currentPage}}
            >
              <div className="cNavbar-inner">
                <div className="cNavbar-image">
                  {theme.checkout && theme.checkout.lineItems.length ? (
                  <span className="cNavbar-cartCount"><span>{ theme.checkout.lineItems.length }</span></span>
                  ): null}
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <div className={`cNavbar-item ${this.state.isMap ? "isMap" : ''} ${this.state.isHomepage ? "isHomepage" : ''} ${!this.state.titleCentered ? "scrolled" : ''}`} title="Cleo's Books">
                  <h1 className={`cNavbar-title ${this.state.isMap ? "isMap" : ''}`}>Cleo's Books</h1>
                </div>
              </div>
            </AniLink>
          </nav>
          )}
      </ThemeContext.Consumer>
    )
  }
}

export default Navbar
