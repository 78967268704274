import React from 'react'
import { kebabCase } from 'lodash'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from "../context/ThemeContext"
import Layout from '../components/Layout'

class CartPageTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }
  componentDidMount() {
    this.context.hideNav()
  }


  render () {
      return (
        <ThemeContext.Consumer>
        {theme => {
          return (
          <div className="cShop cSplitScreen">
            <div className="cShop-left cSplitScreen-content">
              <div className="cSplitScreen-heading">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-title">Cleo's Books</p>
                <AniLink fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}  className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cSplitScreen-path">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-path-text">
                  <AniLink fade duration={0.25} to="/menu">Menu</AniLink>
                  <AniLink fade duration={0.25} to="/shop"> / Shop </AniLink>
                    / Cart</p>
                <AniLink fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}  className="close-icon" aria-label="Close"></AniLink>
              </div>
                <div className="cCart">
                  <div className="cCart-header">
                    <div className="cCart-title">Cart</div>
                  </div>
                  <div className="cCart-info">
                    {!theme.checkout || !theme.checkout.lineItems ? (<p>Loading your cart...</p>) : (theme.checkout && theme.checkout.lineItems && theme.checkout.lineItems && theme.checkout.lineItems.length ? (
                        <div className="cCart-info-inner">
                          {theme.checkout.lineItems.map((item, idx) => (
                            <div className="cCart-item" key={`${kebabCase(item.title)}-${idx}`}>
                              <div className="cCart-item-details">
                                <div className="cCart-image-wrapper">
                                  <img className="cCart-item-image" src={item && item.variant && item.variant.image && item.variant.image.src ? item.variant.image.src : '/img/stack-of-books.png'} alt={`${item.title}`} />
                                </div>
                                <div className="cCart-item-info">
                                  <div className="cCart-item-row">
                                    <div classname="cCart-item-column">
                                      <p>{item.title}</p>
                                      <p>x{item.quantity}</p>
                                    </div>
                                    <div className="cCart-item-price">${(item.variant.priceV2.amount * item.quantity).toFixed(2)}</div>
                                  </div>
                                  <button className="cCart-item-button" onClick={() => {theme.removeLineItemInCart(item.id)}}>Remove</button>
                                </div>
                              </div>
                          </div>
                          ))}
                          <div className="cCart-totals">
                            <div className="cCart-totals-label">Subtotal:</div> <div className="cCart-totals-price">${(theme.checkout.subtotalPriceV2.amount * 1).toFixed(2)}</div>
                          </div>
                          <div className="cCart-btns">
                            <a className="cCart-checkout" href={theme.checkout.webUrl}>Check Out</a>
                          </div>
                        </div>
                        ) : (<p>Your cart is empty</p>))
                      }
                  </div>
                </div>
              </div>
            <AniLink className="cShop-right cSplitScreen-cover" fade to={theme.prevPath ? theme.prevPath : '/'} duration={0.25}>
              <p className="cSplitScreen-cover-title">Cleo's Books</p>
            </AniLink>
          </div>
        )}
      }
      </ThemeContext.Consumer>
    )
  }
}

CartPageTemplate.contextType = ThemeContext

CartPageTemplate.propTypes = {}


const CartPage = () => {
  return (
    <Layout>
      <CartPageTemplate />
    </Layout>
  )
}

CartPage.propTypes = {}

export default CartPage
